@import '../style-guide/components/platform-switcher.less';
@import '../style-guide/components/frequently-asked';


/* GLOWEB-560 menu highlight fix */
@media @desktop {
    #secure-browser {
        &.js-mac {
            .navigation .navigation-nav li.active:nth-child(2),
            .navigation .navigation-nav li.active:nth-child(4) {
                a {
                    border-bottom: none;
                }
            }
        }
        &.js-pc {
            .navigation .navigation-nav li.active:nth-child(3),
            .navigation .navigation-nav li.active:nth-child(4) {
                a {
                    border-bottom: none;
                }
            }
        }

        &.js-android,
        &.js-ios {
            .navigation .navigation-nav li.active:nth-child(-n+3) {
                a {
                    border-bottom: none;
                }
            }
        }
    }
}

/* available for windows */
#top .win,
#comparison .win,
#bottom-dwn .win {
    color: #fff;
    font-size: 14px;
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 25px;
    display: inline-block;
    }

#top .win {
    background-image: url('../../i/page/secure-browser/windows-icon.png');
}
#comparison .win,
#bottom-dwn .win {
    background-image: url('../../i/page/secure-browser/windows-icon-gray.png');
    color: #5c707b;

    p {
        color: #5c707b;
    }
}


/* top */
#top {
min-height: 500px;
padding-top: 106px;
background-image: url('../../i/banner_backgrounds/banner-background-secure-browser-2000x500.jpg');
background-repeat: no-repeat;
background-position: center top;
background-color: #7c6e63;
.container {
text-align:center;
H1,H2 {
color: #fff;
font-family: 'AVGSans_Light',Verdana,sans-serif;
}
H1 {
    margin-top: 28px;
}
H2 {
font-size: 30px;
letter-spacing: -0.75px;
padding-bottom: 20px;
}
h3 {
    color: #ffffff;
}
.flag {
background-color: #0082ee;
padding: 3px 9px;
font-size: 24px;
color:#fff;
font-family: 'AVGSans_Light',Verdana,sans-serif;
}
p {
    color: #ffffff;
}
}
}


/* button price -same for top and bottom */
#top,
#bottom-buy {
.button-price {
    font-size: 30px;
    letter-spacing: -0.03em;
    line-height: 32px;
}
}

/* intro */
#intro {
.container {
text-align:center;
padding-top: 75px;
padding-bottom: 75px;
H2 {
    padding-bottom: 30px;
    margin: 0 auto;
}
.subh2 {
    padding-bottom: 40px;
    font-size: 14px;
}
.row {
    .col {
    padding-left:20px;
    padding-right:20px;
        }
    }
    img {
        margin-left: -90px;
        max-width: 1120px;

        &.small {
            max-width: 700px;
        }
    }
    @media (max-width: 991px) {
        img {
            width: 100%;
            margin-left: 0;
        }
    }
}
}

/* comparison */
#comparison {
    background-color:#eaeef4;
    .container {
        text-align:center;
        padding-top: 75px;
    }
    .avg-comparison-table {
        background-color:#ffffff;
        border: none;
        margin-top:40px;
        margin-bottom: 0;
        padding-top:45px;
      	padding-left:60px;
        padding-right:60px;
        padding-bottom: 10px;
        table thead th{
            padding-bottom: 0;
            .feature {
                width:30%;
            }
        }
        td {
            padding: 33px 5px;
        }
        .two-line td{
            padding: 20px 5px;
        }
        .ficon-tick {
            color:#55bb33 !important;
        }
        .ficon-tick.gray {
            color:#8d9ba2 !important;
        }
        tr:last-child td {
            border:0;
            padding-top:0;
        }
        .button-wrap {
            width: 250px;
            float: right;
        }
        @media (max-width: 767px) {
            .button-wrap {
                float: none;
                padding-top: 20px;
                width: 100%;
            }

            img {
                width: 60%;
            }
            .column.tick {
                border-left: 1px solid #dddddd
            }
        }
    }
    @media (max-width: 767px) {
        .avg-comparison-table {
            padding-left:20px;
            padding-right: 20px;
            margin: 0 10px;
        }
    }
}

/* features */
#features {
.container {
    padding-top: 75px;
    padding-bottom: 15px;
    .row {
      .features-title {
        color: #000;
        font-family: 'AVGSans_Light',Verdana,sans-serif;
        font-size: 36px;
        line-height: 38px;
        letter-spacing: -0.9px;
        padding-bottom: 30px;
      }
        .inner-box {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            &.box-img {
                text-align: center;
            }
            P {
                margin-top:0;
            }
        }
        @media (max-width: 767px) {
            img {
                width: 90%;
            }
        }
    }
}
}


/* bottom-dwn */
#bottom-dwn {
    background-color:#eaeef4;
.container {
    text-align:center;
    padding-top: 75px;
    padding-bottom: 75px;
    H2 {
    font-size:44px;
    line-height: 44px;
    padding-bottom: 20px;
    margin:0;
    }
.product-icon {
    padding-bottom: 50px;
}
p.subh {
    color: #5c707b;
    font-size: 30px;
    line-height: 30px;
    padding-bottom:40px;
}
@media (max-width: 767px) {
    p.subh {
        font-size: 20px;
    }
}
.covers {
    padding-bottom: 50px;
}
.money-back {
max-width: 350px;
margin: 0 auto;
P {
    font-size: 11px;
    font-weight: 400;
    color: #5c707b;
    line-height: 16.05px;
    margin-bottom:0;
}
.money-back-title {
    color: #3ca12e;
    font-family: Verdana;
    font-size: 16px;
}
IMG {
    padding-bottom: 10px;
}
}
}
}

/* requirements */
#requirements {
.container {
padding-top: 75px;
padding-bottom: 75px;
H2 {
margin-top:0;
padding-bottom: 27px;
}
.col {
display: inline-block;
line-height: 70px;
padding: 0 0 0 60px;

    &.windows {
        background: transparent url(../../i/antitrack/icon-windows.png) no-repeat 10px 50%;
    }
    P {
        margin-top:0;
        margin-bottom:0;
        line-height: 30px;
        font-size: 14px;
        padding: 20px;
    }
}
}
}

/**/
.banner-1 .feature-img-left {
    margin-left: -100px;
    margin-right: 0px;
}

  .banner-1 .text {
  margin-bottom:50px;
}

@media only screen and (max-width: 768px) {
  .banner-1 .feature-img-left {
    margin-left: 0;
}
}

.banner-1 .expWrapper .button-small {
    margin-bottom: 20px;
}

#collapse-1 P {
    font-size: 14px;
}

.banner-1 .feature-text-right {
    margin-right: -100px;
}


@media (max-width: 767px) {
    .banner-1 {
        min-height: 380px;
        background-image: none;
    }

    .banner-1 .feature-text-right {
        margin-right: 0px;
    }
}

@media (max-width: 479px) {
    .banner-1 {
        min-height: 400px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: none;
    }
   .banner img {
          width:90%;
  }
}

.banner-2 .text {
    margin-bottom:50px;
}

.banner-2 .expWrapper .button-small {
  margin-bottom: 20px;
}
.expWrapper .text-small {
vertical-align:middle;
}
.expWrapper .expandCollapse::before, .expWrapper .expandCollapse::after {
font-size:20px;
vertical-align: middle;
display: inline-block;
}

#collapse-2 P {
  font-size: 14px;
}

    .banner-2 .text {
  margin-top: 0;
  }

@media (max-width: 767px) {
  .banner-2 {
      min-height: 490px;
      background-image: none;
  }

  .banner-2 .feature-img-right {
      margin-right: 0px;
  }
}

@media (max-width: 479px) {
  .banner-2 {
      min-height: 490px;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: none;
  }
.banner-2 .feature-img-right {
    margin-right:0;
}
}

.banner-3 .feature-img-left {
    margin-left: -100px;
    margin-right: 0px;
}

  .banner-3 .text {
              margin-bottom:50px;
          }

@media only screen and (max-width: 768px) {
  .banner-3 .feature-img-left {
    margin-left: 0;
}
}

.banner-3 .expWrapper .button-small {
    margin-bottom: 20px;
}

#collapse-3 P {
    font-size: 14px;
}

.banner-3 .feature-text-right {
    margin-right: -100px;
}

@media (max-width: 767px) {
    .banner-3 {
        min-height: 380px;
        background-image: none;
    }

    .banner-3 .feature-text-right {
        margin-right: 0px;
    }
}

@media (max-width: 479px) {
    .banner-3 {
        min-height: 400px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: none;
    }
}



// text note below FAQ section
.support-bottom-note {
    position: relative;
    top: -40px;
    margin-bottom: 40px;
}

/* blog-posts */
#blogposts {
    padding-top: 88px;
    padding-bottom: 64px;
    @media @mobile {
        padding-top: 64px;
        padding-bottom: 0;
    }


    .button.secondary > span {
      font-weight: 500;
    }

    .container {
      //overflow-x: hidden;
      padding-bottom: 60px;

      @media @mobile, @tablet {
        padding-bottom: 48px;
      }
    }


    .post {
        @media @mobile {
          margin-bottom: 24px;
        }
      }

    .row {
      &.blog {
        justify-content: center;
        @media @mobile, @tablet {
          padding-left: 20px;
          padding-right: 20px;
        }

      }
      .title {
        max-width: 660px;

        h2 {
          @media @mobile, @tablet {
            font-size: 30px;
            line-height: 40px;
          }
        }
      }
    }


    .carousel-slider {
      .container {
        @media @mobile, @tablet {
          padding-top: 0;
          padding-right: 0;
          padding-left: 20px;
        }
      }
      .tns-nav {
        bottom: -60px;
        @media @mobile, @tablet {
          bottom: 0;
        }
      }
      .tiny-slider {
        .tns-inner:before {
          background-image: linear-gradient(to left, rgba(255,255,255,0.01) 0, #FFFFFF 256px);
        }
        .tns-inner:after {
          background-image: linear-gradient(to right, rgba(255,255,255,0.01) 0, #FFFFFF 256px);
          // @media @mobile, @tablet {
          //   display: block;
          //   position: absolute;
          //   width: 232px;
          //   height: 107%;
          //   right: 0;
          //   top: -5%;
          //   z-index: 3;
          //   background-image: linear-gradient(to right, rgba(255,255,255,0.01) 0, #FFFFFF 256px);
          // }
        }
        .tns-item {
          width: 353px;
          @media @mobile, @tablet {
            margin-right: 20px;
            width: 256px;
          }
        }

        @media @mobile, @tablet {
          overflow: hidden;
          padding-bottom: 56px;
        }

      }
    }

    h2 {
      text-align: left;
      margin-bottom: 0;
        @media @mobile {
          margin-bottom: 24px;
        }
    }

    img {
      width: 100%;
    }

    p {
      color: @Grey-1;
      text-decoration: none;
      font-size: 14px;
    }

    .text-carousel {
      padding: 20px 20px;
      @media @mobile, @tablet {
        padding: 10px 10px;
      }
    }

    a {
      &:hover {
        text-decoration: none;
      }

      &.button {
        &.secondary {
          span {
            border-bottom: 1px solid transparent;
            &:hover {
              border-bottom: 1px solid @Blue-v2;
            }
          }
        }
      }
    }

    .link-all {
      margin-bottom: 0;
      @media @tablet, @desktop {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-bottom: 16px;
      }

      a {
        border-bottom: none;
        &.button {
          display: flex;
          justify-content: flex-end;

          @media @mobile {
            justify-content: flex-start;
          }
        }
      }
    }

    .slider-controls {
      img {
        margin-bottom: 32px;
      }
    }

    .item-container {
      width: 289px;
      height: 100%;
      border: 1px solid #e8e8e8;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media @tablet, @mobile {
        width: 240px;
      }
    }
  }
  /* /blog-posts */

